import React from 'react'

import {GatsbyImage} from 'gatsby-plugin-image'

import usePageNotFound from '@/hooks/images/usePageNotFound'
import LayoutDefault from '@/layouts/LayoutDefault'

export default function PageNotFound() {
  const image = usePageNotFound()
  return (
    <LayoutDefault>
      <div className={'container mx-auto mt-10 mb-16 px-4 md:px-0'}>
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt="Page Not Found"
        />
      </div>
    </LayoutDefault>
  )
}
