import {graphql, useStaticQuery} from 'gatsby'
const queryImage = graphql`
  {
    file(relativePath: {eq: "404Image.png"}) {
      childImageSharp {
        gatsbyImageData(width: 1239)
      }
    }
  }
`
export default function usePageNotFound() {
  const {file} = useStaticQuery(queryImage)
  return file
}
